<!-- 课表 -->
<template>
  <div class="mentor_calendar">
    <!-- 日历需要固定在顶部 -->
    <Gesture
      v-if="calendarList && calendarList.length"
      tag="div"
      class="calendar"
      stop
      :options="{ swipeMaxAngle: 30 }"
      @swipe="handleSwipe"
    >
      <div v-if="calendarList && calendarList.length" class="calendar_list">
        <div class="row">
          <div
            class="col"
            style="flex-direction: column"
            @click="handleSelectDate(item)"
            v-for="(item, index) in calendarList"
            :key="`${item.date}${index}`"
          >
            <div class="day">
              <span :class="{ active: activeDate === index }">{{
                index === 0 && currentToday == today
                  ? "今天"
                  : index === 1 && currentToday == today
                  ? "明天"
                  : index === 2 && currentToday == today
                  ? "后天"
                  : "周" + item.day_str
              }}</span>
            </div>
            <div :class="{ date: true, dateActive: activeDate === index }">
              {{ index === 0 ? item.date : item.date.substring(3) }}
              <div class="dot" v-if="item.classTotal > 0"></div>
            </div>
          </div>
        </div>
      </div>
    </Gesture>

    <!-- 课表不需要滚动分页 -->
    <div class="list" v-show="list.length">
      <CourseCard
        v-for="item in list"
        :key="item.title"
        :info="item"
        @toLeave="toLeave"
      />
    </div>
    <div class="empty" v-show="!list.length">
      <div class="icon"></div>
      <p class="tips">无更多课程安排，若有疑问请和班主任联系</p>
    </div>
    <div class="mentor-reserve">
      <div class="mentor-reserve-text" @click="enterReserve">
        预约<br />上课
      </div>
      <img src="@/assets/images/yysk.png" />
    </div>
  </div>
</template>
<script>
import CourseCard from "./CourseCard.vue";
import { getCalendarAll } from "../../../api/learner";
import dayjs from "dayjs";
import Gesture from "../../../gesture/dist/gesture.vue";
import EventBus from "../../../api/EventBus";

function dayFormat(time, opt = "add") {
  if (opt === "minus") {
    console.log(time);
    console.log(dayjs(dayjs(time).subtract(7, "days")).format("YYYYMMDD"));
    return +dayjs(dayjs(time).subtract(7, "days")).format("YYYYMMDD");
  }
  console.log(time);
  console.log(dayjs(dayjs(time).add(7, "days")).format("YYYYMMDD"));
  return +dayjs(dayjs(time).add(7, "days")).format("YYYYMMDD");
}
export default {
  name: "learner-calendar",
  data() {
    return {
      calendarList: [],
      selectDate: {},
      today: dayjs().format("MM-DD"),
      filter: {
        startTime: null,
        endTime: null,
      },
      test: [
        {
          planId: 12501,
          teacherId: 90,
          teacherName: "后端RD测试",
          teacherAvatar:
            "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83ergwTnJtibq6mqo89XezFticgFtY4odESwUshBDHEAiaDwcwicogUvPbibeicO5RwBmAqnicQtb1mr6okpZg/132",
          groupName: "测试校招开发课程1 第1期",
          timeRegion: "00:00~00:30",
          groupId: 739,
          courseId: 36,
          studentNames: [],
          planStatus: 1,
          startTime: "2022-04-14 00:00:00",
          endTime: "2022-04-14 00:30:00",
          timeConflict: null,
          classTotal: 10,
          classedNum: 4,
          num: 5,
          recordStatus: 1,
        },
      ],
    };
  },
  computed: {
    activeDate() {
      const index = this.calendarList.findIndex((v) => {
        const { timestamp } = this.selectDate;
        if (timestamp) {
          return v.timestamp === timestamp;
        } else {
          return v.date === this.today;
        }
      });
      return index;
    },
    list() {
      return this.calendarList[this.activeDate]?.groupTimeRegions ?? [];
    },
    currentToday() {
      return this.calendarList[0].date;
    },
  },
  methods: {
    handleSwipe(e) {
      let { startTime, endTime } = this.filter;
      startTime = this.calendarList[0].timestamp;
      endTime = this.calendarList[6].timestamp;
      // 向左滑
      e.direction === "Left" && this.slideNext({ startTime, endTime });
      // 向右滑
      e.direction === "Right" && this.slidePre({ startTime, endTime });
    },
    slideNext({ startTime, endTime }) {
      // 开始时间+7d
      console.log("加载后一页");
      this.filter.startTime = dayFormat(startTime);
      this.filter.endTime = dayFormat(endTime);
      this.initData(this.filter);
    },
    slidePre({ startTime, endTime }) {
      // 开始时间-7d
      console.log("加载前一页");
      this.filter.startTime = dayFormat(startTime, "minus");
      this.filter.endTime = dayFormat(endTime, "minus");
      this.initData(this.filter);
    },
    handleSelectDate(item) {
      this.selectDate = item;
    },
    async initData(params = {}) {
      const { list, today } = await getCalendarAll({
        startTime: params.startTime ?? null,
        endTime: params.endTime ?? null,
      });
      this.calendarList = list;
      this.today = today;
    },
    inflate(name) {
      console.log(name);
      if (name === this.$route.name) {
        this.selectDate = {};
        this.filter = {
          startTime: null,
          endTime: null,
        };
        this.initData();
      }
    },
    enterReserve() {
      this.$router.push({ name: "learner-reserve" });
    },
    toLeave(id) {
      console.log(id);
      this.$router.push({
        name: "learner-leave",
        params: {
          planId: id,
        },
      });
    },
  },
  created() {
    this.initData();
    EventBus.$on("BarTabClick", this.inflate);
    console.log(window.localStorage.getItem("token"));
  },
  beforeDestroy() {
    EventBus.$off("BarTabClick", this.inflate);
  },
  mounted() {
    document.title = "课表";
  },
  components: { CourseCard, Gesture },
};
</script>

<style lang="scss" scoped>
.mentor-reserve {
  width: 72px;
  height: 72px;
  position: fixed;
  right: 10px;
  bottom: 185px;
  img {
    width: 72px;
    height: 72px;
  }
  &-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #fff;
    width: 80px;
    text-align: center;
  }
}
</style>

<style lang="stylus" scoped>
@import '../../../common.styl';
@import '../../../theme.styl';

.mentor_calendar {
  padding-top: 92px;

  .calendar {
    height: 92px;
    padding: 18px 12px;
    background: #fff;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 999;

    .day, .date {
      text-align: center;
      flex: 1;
    }

    .day {
      font-weight: 400;
      font-size: 12px;
      color: #86858D;

      span {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        line-height: 30px;
        display: block;
        margin: 0 auto 8px;

        &.active {
          color: #E14247;
        }
      }
    }

    .date {
      position: relative;
      font-weight: 500;
      font-size: 20px;
      color: #171629;

      .dot {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #f52c41;
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .dateActive {
      color: #E14247;
      font-weight: 500;
      font-size: 20px;
    }
  }

  .empty {
    padding-top: 173px;

    .icon {
      width: 132px;
      height: 116px;
      margin: 0 auto;
      bgFill();
      background-image: url('./images/empty2.png');
    }

    .tips {
      padding-top: 32px;
      text-align: center;
      font-weight: 400;
      font-size: 15px;
      color: #999999;
      line-height: 22px;
    }
  }

  .list {
    padding: 10px;
  }
}
</style>