import http from "./http";
import errorCode from "./errorCode";
import dayjs from "dayjs";
import Vue from "vue";
import { getUserInfo } from "@/api/common";
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);
// set default timezone
dayjs.tz.setDefault("Asia/Shanghai");

export function tz(d, format) {
  return dayjs.tz(d, format);
}

// for local programming
// let host = process.env.NODE_ENV === "development" ? "https://dev.offer.sibaiji.cn" : "https://offer.sibaiji.cn";
// // for webview url request
// if (window.location.href.includes('dev.')) {
//   host = "https://dev.offer.sibaiji.cn";
// }
let host = process.env.NODE_ENV === "development" ? "/offerApi" : "/offerApi";
// for webview url request
console.log(process.env.NODE_ENV);
if (window.location.href.includes("dev.")) {
  host = "/offerApi";
}
host = "https://ap.sqjoy.com/offerApi";

function formatDate(time, format = "YYYY-MM-DD", unix) {
  if (unix) {
    return tz(dayjs.unix(time)).format(format);
  }
  return tz(dayjs(time)).format(format);
}

function businessErrorGuard(data, errorMessageMapping = errorCode) {
  let { code, message } = data;
  // code 1028 不能排过去的课程
  if (code !== 200 && code !== 1028) {
    (console.error || console.data).bind(console)("业务错误:", data);
    message = errorMessageMapping[code] || message;
    throw message;
  }
}

// 课表 done
export async function getCalendarAll(params) {
  if (!Vue.prototype.$linkId) {
    let res = await getUserInfo();
    if (res && res.data && res.data.linkId) {
      Vue.prototype.$linkId = res.data.linkId;
      // Vue.prototype.$linkId = 829;
    }
  }
  let { data } = await http.get(`${host}/offer/plan/query`, {
    params: {
      ...params,
      studentId: Vue.prototype.$linkId,
    },
  });

  businessErrorGuard(data);

  function format(res) {
    if (!Array.isArray(res)) return [];
    res = res.map((v) => {
      return {
        ...v,
        date: formatDate(v.timestamp, "MM-DD"),
        day_str: ["日", "一", "二", "三", "四", "五", "六"][
          dayjs(v.timestamp).day()
        ],
      };
    });
    return res;
  }
  const { serverTime } = data.attrMaps;
  return {
    list: format(data.data),
    today: formatDate(serverTime, "MM-DD"),
    serverTime,
  };
}

// 课时记录
export async function getHistoryList(params) {
  let { data } = await http.get(`${host}/offer/courseRecord/queryMyPlan`, {
    params: {
      ...params,
      studentId: Vue.prototype.$linkId,
    },
  });

  businessErrorGuard(data);
  const res = data.data || {};
  function format(res) {
    if (!Array.isArray(res)) return [];
    res = res.map((v) => {
      return {
        ...v,
      };
    });
    return res;
  }
  return {
    list: format(res.list),
    total: res.total,
  };
}

// 确认上课
export async function confirmClass(params) {
  let { data } = await http.post(
    `${host}/offer/courseRecord/confirm`,
    {
      ...params,
      studentId: Vue.prototype.$linkId,
    },
    { json: true }
  );

  businessErrorGuard(data);
  return data.data;
}

// 我的班级  我的课程
export async function getGroupAll(params) {
  let { data } = await http.get(`${host}/offer/group/query`, {
    params: {
      ...params,
      studentId: Vue.prototype.$linkId,
    },
  });
  businessErrorGuard(data);
  return data.data || [];
}

export async function getTimeQuery(params) {
  let { data } = await http.get(`${host}/offer/teacherTime/query`, {
    params,
  });
  businessErrorGuard(data);
  return data.data || [];
}

export async function getGroupDetail(params) {
  let { data } = await http.get(`${host}/offer/group/detail`, {
    params,
  });
  businessErrorGuard(data);
  return data.data || [];
}

export async function postTimeSet(params) {
  let { data } = await http.post(
    `${host}/offer/teacherTime/save`,
    {
      ...params,
      studentId: Vue.prototype.$linkId,
    },
    { json: true }
  );

  businessErrorGuard(data);
  return true;
}

export async function postJoinClass(params) {
  let { data } = await http.post(
    `${host}/offer/group/addGroup`,
    {
      ...params,
      studentId: Vue.prototype.$linkId,
    },
    { json: true }
  );

  businessErrorGuard(data);
  return data.data;
}

export async function getReserveClass() {
  let { data } = await http.get(`${host}/offer/group/reserveQuery`, {
    params: {
      studentId: Vue.prototype.$linkId,
    },
  });
  businessErrorGuard(data);
  return data.data;
}

export async function getReserveTeacher(params) {
  let { data } = await http.get(`${host}/offer/group/teacherQuery`, {
    params,
  });
  businessErrorGuard(data);
  return data.data;
}

export async function saveReservePlan(params) {
  let { data } = await http.post(
    `${host}/offer/group/reservePlan`,
    {
      ...params,
    },
    { json: true }
  );
  businessErrorGuard(data);
  return data.data;
}

export async function checkReserveTime(params) {
  let { data } = await http.post(
    `${host}/offer/group/checkReserveTime`,
    {
      ...params,
    },
    { json: true }
  );
  businessErrorGuard(data);
  return data.data || [];
}

// todo ---新增API
export async function getFreeTimeQuery() {
  let id = Vue.prototype.$linkId;

  let { data } = await http.get(`${host}/offer/teacherTime/${Number(id)}/${1}`);
  businessErrorGuard(data);
  return data.data || [];
}

export async function addFreeTime(params) {
  let { data } = await http.post(
    `${host}/offer/teacherTime/addFreeTime`,
    {
      ...params,
    },
    { json: true }
  );

  businessErrorGuard(data);
  return true;
}
export async function editFreeTime(params) {
  let { data } = await http.post(
    `${host}/offer/teacherTime/edit`,
    {
      ...params,
    },
    { json: true }
  );

  businessErrorGuard(data);
  return true;
}
// 编辑空闲时间
export async function freeTimeEdit(params) {
  let { data } = await http.post(
    `${host}/offer/teacherTime/editFreeTime`,
    {
      ...params,
    },
    { json: true }
  );

  businessErrorGuard(data);
  return true;
}
// 删除空闲时间
export async function freeTimeDel(params) {
  let { data } = await http.post(
    `${host}/offer/teacherTime/del`,
    {
      ...params,
    },
    { json: true }
  );

  businessErrorGuard(data);
  return true;
}
// ---
