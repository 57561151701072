"use strict";

var gesture = require("./gesture.js");

var fingerEvent = [
  "touchStart",
  "touchMove",
  "touchEnd",
  "touchCancel",
  "multipointStart",
  "multipointEnd",
  "tap",
  "doubleTap",
  "longTap",
  "singleTap",
  "rotate",
  "pinch",
  "pressMove",
  "swipe",
];
var gesture_vue = {
  props: {
    tag: {
      type: String,
      default: "div",
    },
    options: {
      type: Object,
      default: function _default() {
        return {};
      },
    },
    stop: {
      type: Boolean,
      default: false,
    },
  },
  data: function data() {
    return {
      gesture: null,
    };
  },
  render: function render(h) {
    return h(this.tag, {}, this.$slots.default);
  },
  mounted: function mounted() {
    var _this = this;

    var options = fingerEvent
      .filter(function(e) {
        return _this.$listeners[e];
      })
      .reduce(
        function(options, event) {
          options[event] = function(evt) {
            _this.$emit(event, evt);
          };
          console.log("options");
          return options;
        },
        {
          stop: this.stop,
        }
      );
    this.gesture = new gesture(this.$el, Object.assign(options, this.options));
  },
  beforeDestroy: function beforeDestroy() {
    this.gesture.destroy();
  },
};

module.exports = gesture_vue;
