<template>
  <div class="course_card" :class="`state_${info.planStatus}`">
    <p class="title">
      <img src="./images/redLine.png" alt="" srcset="" /> {{ info.groupName }}
    </p>
    <div class="row" style="justify-content: start">
      <div
        class="icon mentor_icon"
        :style="`opacity: ${info.planStatus === 2 ? '0.4' : 1};`"
      ></div>
      <p class="mentor">
        {{ info.teacherName }}
      </p>
    </div>
    <div class="progress row" style="justify-content: start">
      <div class="icon" :class="`icon_${info.planStatus}`"></div>
      <div>
        {{ info.timeRegion }}
        <span class="count"
          >{{
            info.recordStatus == 3 || info.recordStatus == 4
              ? "(原第"
              : "(当前第"
          }}{{ info.num + "节/共" + info.classTotal + "节)" }}</span
        >
      </div>
      <div class="leave" @click="$emit('toLeave', info.planId)">
        请假<img src="./images/Vector (7).png" alt="" srcset="" />
      </div>
    </div>
    <!-- <p class="courseTheme" style="color: #0e0e0e; line-height: 17px">
      腾讯会议链接：
    </p>
    <p class="courseTheme alink">
      <a @click="linkClick">{{ info.meetUrl }}</a>
    </p> -->
    <div class="line"></div>
    <p class="courseTheme courseThemeT">
      课程主题：{{ info.topic ? info.topic : "班班暂时还没填写呢~" }}
    </p>
    <div :class="{ courseContent: true, hideContent: show }">
      课程内容：{{ info.content ? info.content : "班班暂时还没填写呢~" }}
    </div>
    <div :class="{ showBtn: true, hideBtn: !show }">
      <div v-if="show" @click="showClick(info)">
        展开课程<img src="./images/Mask.png" alt="" srcset="" />
      </div>
      <div v-else @click="showClick(info)">
        收起课程<img src="./images/Mask2.png" alt="" srcset="" />
      </div>
    </div>
  </div>
</template>
<script>
// planStatus
//   1: '未开始',
//   2: '结束',
//   3: '取消'
export default {
  name: "common-calendar-card",
  props: {
    info: {
      type: Object,
      require: true,
      default: () => {
        return {
          groupName: "",
        };
      },
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    showClick() {
      this.show = !this.show;
    },
    linkClick() {
      this.$toast("请复制链接到浏览器或者腾讯会议app加入会议");
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '../../../common.styl';
@import '../../../theme.styl';

.course_card {
  width: 100%;
  min-height: 86px;
  background: #FFFFFF;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  padding-left: 14px;
  position: relative;
  overflow: hidden;
  justify-content: space-between;
  box-shadow: 0 1px 4px #6666660f;

  // &:before {
  // content: '';
  // position: absolute;
  // left: 0;
  // width: 5px;
  // height: 100%;
  // background: #E14247;
  // top: 0;
  // }
  &.state_2 { // 结束 灰的
    .title, .mentor, .progress {
      color: #C6C6C6;
    }

    &:before {
      background: #E14247;
    }
  }

  &.active { // 即将开始 黄的
    &:before {
      background: #f7b500;
    }
  }

  &+.course_card {
    margin-top: 10px;
  }

  .title {
    color: #0E0E0E;
    line-height: fz18px;
    font-weight: 600;
    font-size: 17px;

    img {
      position: absolute;
      top: 12px;
      left: 0;
      width: 3px;
      height: 16px;
    }
  }

  .icon {
    bgFill();
    width: 11px;
    height: 11px;
    flex-shrink: 0;
  }

  .mentor_icon {
    // border-radius: 50%;
    width: 13px;
    height: 13px;
    background-image: url('./images/medal.png');
    margin-right: 4px;
  }

  .mentor {
    font-size: fz14px;
    color: #10213F;
    line-height: fz14px;
    margin: 8px 0;
    margin-left: 6px;
    vendor(2);
  }

  .progress {
    font-size: fz12px;
    color: #10213F;

    .count {
      margin-left: 4px;
    }

    .icon {
      width: 13px;
      height: 13px;
      margin-right: 10px;

      &.icon_1 {
        background-image: url('./images/时间1.png');
      }

      &.icon_2 {
        background-image: url('./images/时间2.png');
      }

      &.icon_3 {
        background-image: url('./images/时间3.png');
      }
    }
  }

  .leave {
    position: absolute;
    right: 15px;
    font-weight: 400;
    font-size: 14px;
    color: #5379B2;

    img {
      width: 12px;
      height: 14px;
      position: relative;
      top: 3px;
      margin-left: 6px;
    }
  }

  .courseTheme {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #858585;
    margin-top: 5px;
  }

  .courseThemeT {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .courseContent {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #858585;
    word-break: break-word;
  }

  .hideContent {
    height: 0;
    overflow: hidden;
  }

  .showBtn {
    font-weight: 500;
    font-size: 12px;
    color: #5379B2;
    display: flex;
    justify-content: center;
    margin-top: 16px;

    img {
      width: 10px;
      height: 6px;
      margin-left: 6px;
    }
  }

  .hideBtn {
    color: #858585;
  }

  .alink {
    line-height: 8px;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: #E4E4E4;
    margin: 10px 0 0 0;
  }

  a:link {
    color: #0E0E0E;
  }

  a:visited {
    color: #0E0E0E;
  }

  a:hover {
    color: #0E0E0E;
  }

  a:active {
    color: #0E0E0E;
  }
}
</style>  